export default {
  getBlogList: (state) => state.blogList,
  getBlogListError: (state) => state.blogListStatus.error,
  getBlogListIsError: (state) => state.blogListStatus.isError,
  getBlogListIsLoading: (state) => state.blogListStatus.isLoading,
  getBlogItem: (state) => state.blogItem,
  getBlogItemError: (state) => state.blogItemStatus.error,
  getBlogItemIsError: (state) => state.blogItemStatus.isError,
  getBlogItemIsLoading: (state) => state.blogItemStatus.isLoading
};
