import {
  SET_BLOG_LIST,
  SET_BLOG_LIST_LOADING,
  SET_BLOG_LIST_ERROR,
  SET_BLOG_ITEM,
  SET_BLOG_ITEM_LOADING,
  SET_BLOG_ITEM_ERROR
} from './types';

export default {
  [SET_BLOG_LIST](state, blogList) {
    state.blogList = blogList;
  },
  [SET_BLOG_LIST_LOADING](state, isBlogListLoading) {
    state.blogListStatus.isLoading = isBlogListLoading;
  },
  [SET_BLOG_LIST_ERROR](state, { description, code, isError, query }) {
    state.blogListStatus.isError = isError;
    state.blogListStatus.error.code = code;
    state.blogListStatus.error.query = query;
    state.blogListStatus.error.description = description;
  },
  [SET_BLOG_ITEM](state, blogItem) {
    state.blogItem = blogItem;
  },
  [SET_BLOG_ITEM_LOADING](state, isBlogItemLoading) {
    state.blogItemStatus.isLoading = isBlogItemLoading;
  },
  [SET_BLOG_ITEM_ERROR](state, { description, code, isError, query }) {
    state.blogItemStatus.isError = isError;
    state.blogItemStatus.error.code = code;
    state.blogItemStatus.error.query = query;
    state.blogItemStatus.error.description = description;
  }
};
