import sanity from '@/infrastructure/sanity/sanity';
import QUERY_BLOG_LIST, { QUERY_BLOG_ITEM } from './queries';
import {
  SET_BLOG_LIST,
  SET_BLOG_LIST_LOADING,
  SET_BLOG_LIST_ERROR,
  SET_BLOG_ITEM,
  SET_BLOG_ITEM_LOADING,
  SET_BLOG_ITEM_ERROR
} from './types';

export default {
  fetchBlogList: async (context) => {
    context.commit(SET_BLOG_LIST_LOADING, true);
    context.commit(SET_BLOG_LIST_ERROR, { error: null, isError: false });
    sanity.fetch(QUERY_BLOG_LIST).then(
      (coaches) => {
        context.commit(SET_BLOG_LIST, coaches);
      },
      (error) => {
        context.commit(SET_BLOG_LIST_ERROR, { error, isError: true });
      }
    );
    context.commit(SET_BLOG_LIST_LOADING, false);
  },

  fetchBlogItem: async (context, params) => {
    context.commit(SET_BLOG_ITEM_LOADING, true);
    context.commit(SET_BLOG_ITEM_ERROR, { error: null, isError: false });
    sanity.fetch(QUERY_BLOG_ITEM, params).then(
      (blog) => {
        context.commit(SET_BLOG_ITEM, blog);
      },
      (error) => {
        context.commit(SET_BLOG_ITEM_ERROR, { error, isError: true });
      }
    );
    context.commit(SET_BLOG_ITEM_LOADING, false);
  }
};
