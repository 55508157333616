import status from '../status';

const initialState = () => ({
  blogList: [],
  blogItem: null,
  blogListStatus: {...status},
  blogItemStatus: {...status}
});

export default initialState;
