const QUERY_BLOG_LIST = `
    *[_type == "blog"]{
        title,
        description,
        series,
        publishedAt,
        slug,
        "image": image{
            asset->{
                _id,
                url
            }
        },
        "author": coach->name,
        "authorImage": coach->image{
            asset->{
                _id,
                url
            }
        }
    }
`;

export const QUERY_BLOG_ITEM = `
    *[slug.current == $slug]{
        title,
        description,
        publishedAt,
        series,
        slug,
        body,
        "image": image{
            asset->{
                _id,
                url
            }
        },
        "author": coach->name,
        "authorImage": coach->image{
            asset->{
                _id,
                url
            }
        }
    }[0]
`;

export default QUERY_BLOG_LIST;
