<template>
  <div class="h-full w-screen bg-black">
    <LoadingState v-if="getBlogListIsLoading" />
    <ErrorState
      v-else-if="getBlogListIsError"
      :error="getBlogListError"
      :retry="fetchBlogList"
    />

    <PageSection
      v-else
      clas="flex flex-col"
    >
      <div
        :class="[
          'grid grid-cols-2 gap-12 pt-8',
        ]"
      >
        <div
          v-for="(item, index) in getBlogList"
          :key="index"
          class="lg:col-span-1 col-span-2 flex flex-grow"
        >
          <Card
            bordered
            :title="`${item.series}: ${item.title}`"
            :subtitle="item.publishedAt"
            :body="item.description"
            :background="'bg-secondary-200'"
          >
            <template v-slot:media>
              <div
                class="h-48 bg-no-repeat bg-cover bg-center my-6"
                :style="item.image.asset.url && `background-image: url(${item.image.asset.url});`"
              >
                <div class="h-full w-full bg-black bg-opacity-50 flex flex-col" />
              </div>
            </template>
            <template v-slot:footer>
              <div
                class="flex flex-row justify-between items-center"
              >
                <Author
                  :image="item.authorImage.asset.url"
                  :author="item.author"
                />

                <router-link :to="`/blog/${item.slug.current}`">
                  <button class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4 w-full">
                    Read more
                  </button>
                </router-link>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </PageSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ErrorState, LoadingState, Card, Author, PageSection } from '@/pages/shared/index';

import blogStore from '@/infrastructure/store/modules/blog/';


export default {
  name: 'BlogIndex',
  components: {
    LoadingState,
    ErrorState,
    PageSection,
    Card,
    Author
  },
  computed: {
    ...mapGetters('blog', [ 'getBlogList', 'getBlogListError', 'getBlogListIsError', 'getBlogListIsLoading' ])
  },
  async created() {
    if(!this.$store.hasModule('blog')) this.$store.registerModule('blog', blogStore);
    await this.fetchBlogList();
  },
  methods: {
    ...mapActions('blog', [ 'fetchBlogList' ])
  }
};
</script>
